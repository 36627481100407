import React from "react";

/**
 *
 * wss.gldnsoft.com/core21615
 * wss.gldnsoft.com/core21616
 *  локально
 * wss-local.gldnsoft.com/core21615
 * wss-local.gldnsoft.com/core21616
 */

const CONSTATNTS = {
  VERSION: "1.2.0",
  APP_FLG: true,
  CHANGE_URL: "CHANGE_URL",
  RESET_ERROR_REGISTRATION: "RESET_ERROR_REGISTRATION",
  ROUTING: "ROUTING",
  SAVE_CURRENT_URL: "SAVE_CURRENT_URL",
  IS_LOADED_DATA: "IS_LOADED_DATA",
  RESET_STATE: "RESET_STATE",
  SOCKET_ADDRESS: 1, //SOCKET_ADDRESS,
  SOCKET_CONNECT: "SOCKET_CONNECT_NEW",
  SOCKET_MESSAGE: "SOCKET_MESSAGE",
  SOCKET_ERROR: "SOCKET_ERROR",
  SOCKET_CLOSE: "SOCKET_CLOSE",
  SOCKET_OPEN: "SOCKET_OPEN",
  SAVE_SPORT_ENTITY: "SAVE_SPORT_ENTITY_NEW",
  SAVE_CATEGORY_ENTITY: "SAVE_CATEGORY_ENTITY",
  SAVE_TOURNAMENT_ENTITY: "SAVE_TOURNAMENT_ENTITY",
  SAVE_MARKET_ENTITY: "SAVE_MARKET_ENTITY",
  SAVE_MARKET_BY_NUM_ENTITY: "SAVE_MARKET_ENTITY",
  SAVE_LINES: "SAVE_LINES",
  SAVE_LINES_BY_CK: "SAVE_LINES_BY_CK_NEW",
  SAVE_EVENTS: "SAVE_EVENTS",
  SAVE_NEW_EVENTS: "SAVE_NEW_EVENTS",
  SAVE_EVENTS_BY_GB: "SAVE_EVENTS_BY_GB",
  OPEN_MENU: "OPEN_MENU",
  CLOSE_MENU: "CLOSE_MENU",
  SAVE_CURRENT_SPORTS: "SAVE_CURRENT_SPORTS",
  SAVE_USER: "SAVE_USER",
  AUTORIZE_FAIL: "AUTORIZE_FAIL",
  RESPONSE_AUTORIZE: "RESPONSE_AUTORIZE",
  AUTORIZE_SUCCSESS: "AUTORIZE_SUCCSESS",
  ADD_SPORT_TO_FAV_PREMATCH: "ADD_SPORT_TO_FAV_PREMATCH",
  ADD_SPORT_TO_FAV_LIVE: "ADD_SPORT_TO_FAV_LIVE",
  REMOVE_SPORT_FROM_FAV_PREMATCH: "REMOVE_SPORT_FROM_FAV_PREMATCH",
  REMOVE_SPORT_FROM_FAV_LIVE: "REMOVE_SPORT_FROM_FAV_LIVE",
  ADD_TOURNAMENT_TO_FAV_PREMATCH: "ADD_TOURNAMENT_TO_FAV_PREMATCH",
  REMOVE_TOURNAMENT_FROM_FAV_PREMATCH: "REMOVE_TOURNAMENT_FROM_FAV_PREMATCH",
  ADD_EVENTS_TO_FAV_LIVE: "ADD_EVENTS_TO_FAV_LIVE",
  ADD_EVENT_TO_FAV_LIVE: "ADD_EVENT_TO_FAV_LIVE",
  REMOVE_EVENT_FROM_FAV_LIVE: "REMOVE_TOURNAMENT_FROM_FAV_LIVE",
  ADD_EVENTS_TO_FAV_PREMATCH: "ADD_EVENTS_TO_FAV_PREMATCH",
  ADD_EVENT_TO_FAV_PREMATCH: "ADD_EVENT_TO_FAV_PREMATCH",
  REMOVE_EVENT_FROM_FAV_PREMATCH: "REMOVE_EVENT_FROM_FAV_PREMATCH",
  OPEN_ACCOUNT_COMPONENTS: "OPEN_ACCOUNT_COMPONENTS",
  CLOSE_ACCOUNT_COMPONENTS: "CLOSE_ACCOUNT_COMPONENTS",
  LOGOUT_ACCOUNT: "LOGOUT_ACCOUNT",
  BONUS_BALANCE_ACCOUNT: "BONUS_BALANCE_ACCOUNT",
  MAIN_BALANCE_ACCOUNT: "MAIN_BALANCE_ACCOUNT",
  CHANGE_VIEW_TABLE_FILTER: "CHANGE_VIEW_TABLE_FILTER",
  CHANGE_VIEW_TOP_NAV: "CHANGE_VIEW_TOP_NAV",
  CHANGE_TIME_FILTER_PREMATCH: "CHANGE_TIME_FILTER_PREMATCH",
  SAVE_COEF_BUILER: "SAVE_COEF_BUILER",
  DELETE_OR_PAUSE_EVENT: "DELETE_OR_PAUSE_EVENT",
  DELETE_EVENT: "DELETE_EVENT",
  BET_STOP: "BET_STOP",
  PAINTING_DICTIONARY_MOBILE: "PAINTING_DICTIONARY_MOBILE",
  PAINTING_DICTIONARY_TEABLET: "PAINTING_DICTIONARY_TEABLET",
  CHANGE_VIEW_PAINTING: "CHANGE_VIEW_PAINTING",
  CHANGE_SEARCH_RESULT: "CHANGE_SEARCH_RESULT",
  CLOSE_SEARCH: "CLOSE_SEARCH",
  OPEN_SEARCH: "OPEN_SEARCH",
  COUPON_CHANGE_DEFAULT_OPEN: "COUPON_CHANGE_DEFAULT_OPEN",
  ADD_ORDINAR_TO_COUPON: "ADD_ORDINAR_TO_COUPON",
  REMOVE_ORDINAR_FROM_COUPON: "REMOVE_ORDINAR_FROM_COUPON",
  COUPON_TOGGLE_OUTCOME: "COUPON_TOGGLE_OUTCOME",
  OPEN_COUPON: "OPEN_COUPON",
  CLOSE_COUPON: "CLOSE_COUPON",
  ADD_ORDINAR_INFO: "ADD_ORDINAR_INFO",
  REMOVE_ORDINAR_INFO: "REMOVE_ORDINAR_INFO",
  ADD_EXPRESS_COEF: "ADD_EXPRESS_COEF",
  REMOVE_EXPRESS_COEF: "REMOVE_EXPRESS_COEF",
  ADD_SYSTEM_COEF: "ADD_SYSTEM_COEF",
  REMOVE_SYSTEM_COEF: "REMOVE_SYSTEM_COEF",
  ADD_ORDINAR_COEF: "ADD_ORDINAR_COEF",
  REMOVE_ORDINAR_COEF: "REMOVE_ORDINAR_COEF",
  ADD_SUM_INPUT_ORDINAR: "ADD_SUM_INPUT_ORDINAR",
  ADD_SUM_INPUT_BUILDER: "ADD_SUM_INPUT_BUILDER",
  ADD_SUM_INPUT_EXPRESS: "ADD_SUM_INPUT_EXPRESS",
  ADD_SUM_INPUT_SYSTEM: "ADD_SUM_INPUT_SYSTEM",
  SET_SUM_INPUT_BUILDER: "SET_SUM_INPUT_BUILDER",
  SET_SUM_INPUT_ORDINAR: "SET_SUM_INPUT_ORDINAR",
  SET_SUM_INPUT_EXPRESS: "SET_SUM_INPUT_EXPRESS",
  SET_SUM_INPUT_SYSTEM: "SET_SUM_INPUT_SYSTEM",
  REMOVE_SETTING_SUM_INPUT_ORDINAR: "REMOVE_SETTING_SUM_INPUT_ORDINAR",
  REMOVE_SUM_INPUT_ORDINAR: "REMOVE_SUM_INPUT_ORDINAR",
  CLEAR_COUPON: "CLEAR_COUPON",
  SAVE_MAX_PAY_COUPON: "SAVE_MAX_PAY_COUPON",
  DELETE_ORDINAR_MAX_PAY: "DELETE_ORDINAR_MAX_PAY",
  SEND_TICKET: "SEND_TICKET",
  ADD_BB_GROUP: "ADD_BB_GROUP",
  ADD_MULTIBETS_INFO: "ADD_MULTIBETS_INFO",
  CHANGE_SYSTEM_RANG: "CHANGE_SYSTEM_RANG",
  ADD_SENDING_DATA: "ADD_SENDING_DATA",
  ADD_RESPONSE_TICKET: "ADD_RESPONSE_TICKET",
  COUPON_BUILDER: "COUPON_BUILDER",
  SHOW_MODAL: "SHOW_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  SET_CHANGING_COEFS: "SET_CHANGING_COEFS",
  SET_FAST_BET: "SET_FAST_BET",
  SET_ALL_SETTINGS_COUPON: "SET_ALL_SETTINGS_COUPON",
  ACCEPT_CHANGES: "ACCEPT_CHANGES",
  REMOVE_ONE_BUILDER: "REMOVE_ONE_BUILDER",
  REMOVE_ONE_BUILDER: "REMOVE_ONE_BUILDER",
  REMOVE_ALL_BUILDER: "REMOVE_ALL_BUILDER",
  NOT_USE_NUMBER: "NOT_USE_NUMBER",
  USE_NUMBER: "USE_NUMBER",
  LOGIN_USE: "LOGIN_USE",
  LOGIN_USE_REGISTRATION: "LOGIN_USE_REGISTRATION",
  NOT_LOGIN_USE: "NOT_LOGIN_USE",
  USER_DAY_OR_NAME: "USER_DAY_OR_NAME",
  EMAIL_USE: "EMAIL_USE",
  SAVE_TICKETS: "SAVE_TICKETS",
  SAVE_BALANCE: "SAVE_BALANCE",
  ADD_EDITING_TICKET: "ADD_EDITING_TICKET",
  ADD_UNCONFIRMED_BET: "ADD_UNCONFIRMED_BET",
  DELETE_UNCONFIRMED_BET: "DELETE_UNCONFIRMED_BET",
  SAVE_MAX_PAY_EDITING_TICKET: "SAVE_MAX_PAY_EDITING_TICKET",
  ERROR_CODE_BUILDER: "ERROR_CODE_BUILDER",
  REMOVE_ERROR_BUILDER: "REMOVE_ERROR_BUILDER",
  ADD_PRELOADER: "ADD_PRELOADER",
  DO_CASHOUT: "DO_CASHOUT",
  REMOVE_PRELOADER_TICKET: "REMOVE_PRELOADER_TICKET",
  SET_TYPE_SENDING_TICKET: "SET_TYPE_SENDING_TICKET",
  SAVE_CHANGES_TICKET: "SAVE_CHANGES_TICKET",
  REMEMBER_ADVICE: "REMEMBER_ADVICE",
  GET_EXTRALINE: "GET_EXTRALINE",
  ADD_BETS_EDITING_TICKET: "ADD_BETS_EDITING_TICKET",
  ADD_STAKE_EDITING_TICKET: "ADD_STAKE_EDITING_TICKET",
  CALCULATE_TICKET: "CALCULATE_TICKET",
  CALCULATE_BET: "CALCULATE_BET",
  EDIT_BUILDER: "EDIT_BUILDER",
  SAVE_EXTRALINES_ARRAY_NEED_TO_REQUEST:
    "SAVE_EXTRALINES_ARRAY_NEED_TO_REQUEST",
  CHANGE_VIEW_HISTORY_OPERATION: "CHANGE_VIEW_HISTORY_OPERATION",
  SAVE_USER_OPERATIONS: "SAVE_USER_OPERATIONS",
  SET_CURRENT_DATE_HISTORY_OPERATIONS: "SET_CURRENT_DATE_HISTORY_OPERATIONS",
  ERROR_ATHORIZE_REQ: "ERROR_ATHORIZE_REQ",
  SHOW_MODAL_TEST: "SHOW_MODAL_TEST",
  CLOSE_MODAL_TEST: "CLOSE_MODAL_TEST",
  SECCESS_REGISTRATION: "SECCESS_REGISTRATION",
  ERROR_MSG_REGISTRATION: "ERROR_MSG_REGISTRATION",
  ERROR_MSG_CODE: "ERROR_MSG_CODE",
  ERROR_OLD_YEARS: "ERROR_OLD_YEARS",
  ERROR_CODE_REGISTRATION: "ERROR_CODE_REGISTRATION",
  UPDATE_INDENT_COMPONENT_VERIFICATION: "UPDATE_INDENT_COMPONENT_VERIFICATION",
  UPDATE_DOCUMENTS_COMPONENT_VERIFICATION:
    "UPDATE_DOCUMENTS_COMPONENT_VERIFICATION",
  UPDATE_DOCUMENTS_COMPONENT_VERIFICATION:
    "UPDATE_DOCUMENTS_COMPONENT_VERIFICATION",
  UPDATE_CALL_ME_RESPONSE_VERIFICATION: "UPDATE_CALL_ME_RESPONSE_VERIFICATION",
  UPDATE_VERIFICATION_STATUS: "UPDATE_VERIFICATION_STATUS",
  SEND_NEW_PSW: "SEND_NEW_PSW",
  SEND_CODE_NUMBER: "SEND_CODE_NUMBER",
  SEND_NEW_PSW: "SEND_NEW_PSW",
  SEND_CODE_RECOVER: "SEND_CODE_RECOVER",
  CHANGE_PSW_MY_ACCOUNT: "CHANGE_PSW_MY_ACCOUNT",
  FAIL_CHANGE_PSW: "FAIL_CHANGE_PSW",
  CHANGE_STATE_CONNECT_WITHDRAWAL_CHANELS:
    "CHANGE_STATE_CONNECT_WITHDRAWAL_CHANELS",
  CHANGE_STATE_CONNECT_REPLENISHMENT_CHANELS:
    "CHANGE_STATE_CONNECT_REPLENISHMENT_CHANELS",
  CHANGE_WITHDRAWAL_CHANELS: "CHANGE_WITHDRAWAL_CHANELS",
  CHANGE_REPLENISHMENT_CHANELS: "CHANGE_REPLENISHMENT_CHANELS",
  REMOVE_UNCONFIRMED_BETS: "REMOVE_UNCONFIRMED_BETS",
  // REGISTRATION
  ERROR_CODE_PHONE_REGISTRATION: "ERROR_CODE_PHONE_REGISTRATION",
  NO_USE_PHONE_REGISTRATION: "NO_USE_PHONE_REGISTRATION",
  NO_USE_DATA_REGISTRATION: "NO_USE_DATA_REGISTRATION",
  SECCUESS_REGISTRATION: "SECCUESS_REGISTRATION",
  NO_USE_LOGIN_REGISTRATION: "NO_USE_LOGIN_REGISTRATION",
  NO_USE_EMAIL_REGISTRATION: "NO_USE_EMAIL_REGISTRATION",
  LOADER_REGISTRATION_ACTION: "LOADER_REGISTRATION_ACTION",
  AUTHORIZE_RECOVER_PSW: "AUTHORIZE_RECOVER_PSW",
  ADD_MATCH_STATUSES: "ADD_MATCH_STATUSES",
  CLOSE_WINDOW_REDISTRAION: "CLOSE_WINDOW_REDISTRAION",
  ADD_RESPONSE_ENDING_BONUS_ON_DEPOSIT: "ADD_RESPONSE_ENDING_BONUS_ON_DEPOSIT",
  ADD_ORDINARS_TO_COUPON: "ADD_ORDINARS_TO_COUPON",
  ADD_UNCONFIRMED_BET_WHEN_EDITING: "ADD_UNCONFIRMED_BET_WHEN_EDITING",
  REMEMBER_ORDINARS: "REMEMBER_ORDINARS",
  CHANGE_VIEW_OUTRIGHTS_TABLE: "CHANGE_VIEW_OUTRIGHTS_TABLE",
  LANGUAGE_USER: "LANGUAGE_USER",
  SET_MAIN_SETTIN: "SET_MAIN_SETTIN",
  REDDIRECT_REGISTRATION: "REDDIRECT_REGISTRATION",
  MODAL_REGISTRATION_SUCCESS: "MODAL_REGISTRATION_SUCCESS",
  TIMER_REGISTRATION: "TIMER_REGISTRATION",
  CHANGE_VIEW_SINGLE_BET: "CHANGE_VIEW_SINGLE_BET",
  ERROR_RECOVERY_PSW: "ERROR_RECOVERY_PSW",
  INPUT_SMS_CODE_RECOVERY_PSW: "INPUT_SMS_CODE_RECOVERY_PSW",
  SAVE_NEW_PSW: "SAVE_NEW_PSW",
  RECOVER_SMS_VALID: "RECOVER_SMS_VALID",
  SUCCESS_CHANGE_PSW: "SUCCESS_CHANGE_PSW",
  RESET_STATE_RECOVER_PASSWORD: "RESET_STATE_RECOVER_PASSWORD",
  TEST_MODAL_STATE: "TEST_MODAL_STATE",
  CHANGE_SIZE_SCREEN: "CHANGE_SIZE_SCREEN",
  CHANGE_ACTIVE_MENU: "CHANGE_ACTIVE_MENU",
  BOOKMAKER_ADMIN: "BOOKMAKER_ADMIN",
  BOOKMAKER_TABS: "BOOKMAKER_TABS",
  SAVE_BOOKMAKER_GROUP: "SAVE_BOOKMAKER_GROUP",
  SAVE_BOOKMAKER_CLIENTS: "SAVE_BOOKMAKER_CLIENTS"
};

export const VERSION_CHANGES = () => {
  return (
    <>
      <div>Актуальная версия {CONSTATNTS.VERSION}</div>
      <div>Активна версия разработчика </div>
    </>
  );
};

// local,
export const isDevelop = false; // true - с отображением доп данных, false - без них
export const isCupis = false; // true - c ЦУПИС, false - без него
export const isOnServer = false; //  true - POST, false - GET
export const URL_MATCH_STATUSES = "/matchStatuses.json";

export default CONSTATNTS;

/**
 * 
 * 
 * 
 * dispatch(
{
	type:"SAVE_EVENTS",	
	events: new Map([["0019451468", {...store.server.eventsAndLines.events.get("0019451468"), status: 0}]]),
	eventsMap: store.server.eventsAndLines.events
})

console.log изменение статуса евента из консоли 
 */

export const varibleCss = {
  "--active-bets": "base-7",
  "--body-bg": "base-1",

  "--header-color-bg": "base-1",
  "--header-color-active-nav": "base-4",
  "--header-color-text": "base-3",
  "--header-color-text-active": "base-2",
  "--header-color-active-balance": "base-6",

  "--header-bottom-line-bg": "base-1",
  "--header-bottom-arrow": "base-4",
  "--header-boottom-color-text": "base-3",

  "--coupon-bg": "base-2",
  "--coupon-bg-sml": "base-2",
  "--coupon-bg-sml-title": "base-5",
  "--coupon-bg-sml-title-border": "base-2",
  "--coupon-bg-sml-title-border-bottom": "base-2",
  "--coupon-bg-bet-sml": "base-1",
  "--coupon-sml-text-color": "base-3",
  "--coupon-sml-input-bg": "base-8",
  "--coupon-sml-input-text-color": "base-3",
  "--coupon-bg-bet": "base-2",
  "--coupon-bg-bet-sum": "base-5",
  "--coupon-button": "base-4",
  "--coupon-text-button-color": "base-2",
  "--coupon-title-color": "base-2",
  "--coupon-coef-color": "base-4",
  "--coupon-title-text-not-active": "base-8",
  "--coupon-title-text-active": "base-2",
  "--coupon-bet-text-gray": "base-5",
  "--coupon-sum-bg": "base-5",
  "--coupon-sum-title-bg": "base-3",
  "--coupon-sum-title-text-color": "base-11",
  "--coupon-sum-title-text-color-number": "base-2",
  "--scroll-sports-slider": "base-1",
  "--scroll-sports-active-sport": "base-4",
  "--scroll-sports-inactive": "base-3",
  "--scroll-sports-active-border": "base-7",

  "--title-table-line-bg": "base-2",
  "--title-table-line-text": "base-3",
  "--title-table-line-arrow": "base-2",

  "--line-title-bg": "base-8",
  "--lint-title-color-text": "base-3",
  "--line-title-color-sport": "base-4",

  "--line-content-bg": "base-2",
  "--line-content-color-text": "base-3",
  "--line-content-color-text-counter-markets": "base-4",
  "--line-content-color-text-data": "base-5",
  "--line-content-color-border-coef": "base-1",

  "--authorize-login-bg-input": "base-8",
  "--authorize-login-color-text-input": "base-3",
  "--authorize-login-color-text": "base-8",
  "--authorize-login-color-title": "base-3",
  "--authorize-login-bg-button": "base-4",
  "--authorize-login-bg-checkbox-text": "base-3",
  "--authorize-login-bg-checkbox:": "base-4",
  "--authorize-login-sub-button-recovery-text": "base-5",
  "--authorize-login-sub-button-border": "base-8",
  "--authorize-registration-border-button-bg": "base-4",
  "--line-content-bg-list-coef-active": "base-4",
  "--line-content-bg-list-coef-text-color": "base-2",
  "--line-content-bg-list-coef-open-bg": "base-3",
  "--line-content-bg-list-coef-open-bg-active-coef": "base-4",

  "--coupon-bg": "base-2",
  "--coupon-bg-sml": "base-2",
  "--coupon-bg-sml-title": "base-5",
  "--coupon-bg-sml-title-border": "base-2",
  "--coupon-bg-sml-title-border-bottom": "base-2",
  "--coupon-bg-bet-sml": "base-2",
  "--coupon-sml-text-color": "base-3",
  "--coupon-sml-input-bg": "base-8",
  "--coupon-sml-input-text-color": "base-3",
  "--coupon-bg-bet": "base-2",
  "--coupon-bg-bet-sum": "base-5",
  "--coupon-button": "base-4",
  "--coupon-text-button-color": "base-2",
  "--coupon-title-color": "base-2",
  "--coupon-coef-color": "base-4",
  "--coupon-title-text-not-active": "base-8",
  "--coupon-title-text-active": "base-2",
  "--coupon-bet-text-gray": "base-5",
  "--coupon-sum-bg": "base-5",
  "--coupon-sum-title-bg": "base-3",
  "--coupon-sum-title-text-color": "base-11",
  "--coupon-sum-title-text-color-number": "base-2",

  "--button-sml-size-betSlip": "base-3",
  "--button-sml-size-color-text": "base-2",
  "--button-sml-size-color-circle": "base-4",
  "--button-sml-color-border": "base-5",
  "--button-sml-color-close": "base-3",
  "--button-other-bg": "base-4",

  "--keyboards-number": "base-3",
  "--keyboards-color-border": "base-2",

  "--authorize-form-bg": "base-2",
  "--authorize-form-bg-active-tab": "base-8",
  "--authorize-form-bg-tab": "base-2",
  "--authorize-form-color-text-active-tab": "base-3",

  "--authorize-login-bg-input": "base-8",
  "--authorize-login-color-text-input": "base-3",
  "--authorize-login-color-text": "base-8",
  "--authorize-login-color-title": "base-3",
  "--authorize-login-bg-button": "base-4",
  "--authorize-login-bg-checkbox-text": "base-8",
  "--authorize-login-bg-checkbox:": "base-4",
  "--authorize-login-sub-button-recovery-text": "base-5",
  "--authorize-login-sub-button-border": "base-8",
  "--authorize-registration-border-button-bg": "base-4",

  "--authorize-bottom-sub-bg": "base-3",
  "--authorize-bottom-sub-text-color": "base-1",

  "--calendar-bg-column": "base-2",
  "--calendar-border": "base-1",
  "--calendar-button-bg": "base-8",
  "--calendar-button-color-text": "base-3",
  "--calendar-color-text-column": "base-3",
  "--calendar-color-text-bg-active": "base-6",
  "--calendar-color-text-active": "base-3",

  "--country-bg": "base-2",
  "--country-color-text": "base-8",

  "--calendar-bg-svg": "base-2",
  "--color-btn-color-text": "base-2",

  "--swither-lang-bg-active": "base-8",
  "--swither-lang-bg-active-not": "base-5",
  "--swither-lang-not-active": "base-1",

  "--scroll-background-color": "base-3",
  "--scroll-color": "base-1",
  "--scroll-active-color": "base-5",

  "--mybets-title-bg": "base-2",
  "--mybets-title-active": "base-2",
  "--mybets-title-text-not-active": "base-8",
  "--mybets-title-text-active": "base-3",
  "--mybets-filtrs-top": "base-2",
  "--mybets-filtrs-text-color": "base-3",
  "--mybets-filtrs-text-border-color": "base-8",
  "--mybets-bets-edit-bg": "base-8",
  "--mybets-bets-edit-text-color": "base-3",
  "--mybets-bets-button-chash-out": "base-8",
  "--mybets-filters-open-bg": "base-8",

  "--mybets-bets-bg": "base-2",

  "--mybets-list-dropDown-bg": "base-8",
  "--mybets-list-dropDown-border": "base-2",
  "--mybets-list-dropDown-active": "base-8",
  "--mybets-list-history-active": "base-4",
  "--mybets-list-history-bg": "base-1",

  "--registration-color-button-next": "base-5",
  "--registration-color-button-next-bg-notactive": "base-8",
  "--registration-color-button-next-bg-active": "base-4",
  "--registration-name-input-color-text": "base-3",
  "--mybets-calendar-bg": "base-2",
  "--mybets-calendar-select-day": "base-4",
  "--mybets-calendar-select-day": "base-4",
  "--mybets-color-counter-events-ticket": "base-8",
  "--mybets-lines-gradient": "base-5",
  "--mybets-lines-gradient-bg": "base-2",
  "--mybets-bg-sport": "base-3",
  "--ticket-border": "base-8",

  "--error-color-red": "base-9",
  "--color-text-white": "base-3",
  "--color-btn-bg": "base-4",
  "--color-other-coef": "base-4",

  "--editing-border": "base-4",

  "--ticket-editing": "base-8",
  "--mybets-dropdown-edit-bg": "base-8",
  "--mybets-dropdown-edit-bg-active": "base-8",

  "--bg-site": "base-1",

  "--registration-color-button-next": "base-5",
  "--registration-color-button-next-bg-notactive": "base-8",
  "--registration-color-button-next-bg-active": "base-4",
  "--registration-name-input-color-text": "base-5",

  "--mybets-profil-bg": "base-2",
  "--mybets-profil-border-personal-info-change-psw": "base-1",

  "--input-bg": "base-5",
  "--button-bg": "base-4",

  "--filters-sports-bg": "base-8",
  "--active-item-menu-bg": "base-8",
  // my redesign
  "--footer-bg": "base-1",
  "--filter-time-bg": "base-1",

  "--my-account-tittle-bg": "base-1",
  "--green-rose": "base-1",

  "--default": "base-9",
  "--right-component-coupon-header-item-color": "base-2",
  "--right-component-coupon-ordinar-color": "base-2",
  "--right-component-coupon-ordinar-border-color": "base-1",

  "--painting-item": "base-2",

  "--dropdown-color": "base-2",

  "--button-accept": "base-1",
  "--bad-modal-bets": "base-3",

  "--active-coef-color": "base-5",
  "--active-coef-color-border": "base-2",

  "--list-match-categories-bg": "base-2",
  "--list-match-categorise-border-color": "base-1",

  "--change-password-button-cansel": "base-4",

  "--user-menu-color-active-balance": "base-6",

  "--bets-window-title-bg": "base-1",
  "--bets-window-title-active-bg": "base-2",
  "--bets-window-title-border-color": "base-2",
  "--form-wrapper-text": "base-11",
  "--painting-statistic-bg-color": "base-8",
  "--user-account-score-flex-bg": "base-8",
  "--bets-window-seting-bg-color": "base-2",
  "--bets-window-seting-text-color": "base-3",

  "--rules-bg-color": "base-2",
  "--about-us-bg-color": "base-2",
  "--partnership-bg-color": "base-2",
  "--money-action-bg-color": "base-2",
  "--modal-cashout-bg-color": "base-8",
  "--bet-advice-bg-color": "base-2",
  "--bet-aside-money-bg-color": "base-8",
  "--bet-aside-pay-bg-color": "base-8",

  "--active-painting-button-color": "base-4",

  "--change-password-button-border-color": "base-4",
  "--change-password-button-bg-color": "base-4",

  "--bad-modal-bets-costructor-bg-color": "base-8",

  "--constructor-mini-bg-color": "base-1",

  "--search-active-bg-color": "base-2",
  "--line-dropdown-active-coef-bg-color": "base-7"
};
