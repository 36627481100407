import React from "react";
// import "./style.css";

export default function index() {
  return (
    <div className="block-elements">
      <div className="full-elements elements1" />
      <div className="full-elements elements2" />
      <div className="full-elements elements3" />
    </div>
  );
}
