import React from "react";

// import "./style/responsible.css";

// const Responsible = () => (
//   <div className="responsible">
//     <div className="responsible__header">
//       <span>Ответственная игра</span>
//     </div>
//     <div className="responsible__text">
//       <div className="responsible__component">
//         <p>
//           Букмекерская компания Alfabet заботится о своих игроках, поэтому перед
//           началом игры мы настоятельно рекомендуем Вам ознакомиться с политикой
//           ответственной игры.
//         </p>
//         <p>
//           Мы с полной ответственностью подходим к вопросу о возможном
//           возникновении зависимости от азартных игр и обоснованном решении о
//           финансовых тратах.{" "}
//         </p>
//         <p>
//           Букмекер Alfabet дает гарантии защиты своих игроков от чрезмерного
//           пристрастия к игре и не допускать несовершеннолетних к азартным играм.
//         </p>
//         <p>
//           Мы обязуемся давать полный доступ к информации и условия, необходимые
//           для осознанного принятия решений о тратах. Также у нас вы получите
//           опции для помощи, настройки и управления собственным счетом.
//         </p>
//         <p>
//           Alfabet дает своим игрокам максимальный комфорт и безопасность, чтобы
//           вы могли проводить время у нас в свое удовольствие и не волноваться о
//           последствиях.{" "}
//         </p>
//       </div>

//       <div className="responsible__component">
//         <div className="responsible__title">Запрет для несовершеннолетних </div>
//         <p>
//           Alfabet запрещает азартную игру лицам не достигшим 18 лет. Не
//           пытайтесь зарегистрироваться у нас. В соответствии с законодательством
//           РФ, это является нарушением закона.
//         </p>
//         <p>
//           В борьбе с тем, чтобы не допустить несовершеннолетнего гражданина к
//           азартной игре, Alfabet требует при регистрации предъявить документ,
//           удостоверяющий личность (паспорт). Если вы регистрируете игровой счет
//           на сайте Geenbet, то для прохождения процедуры идентификации вам
//           необходимо прийти в пункт приема ставок по одному из адресов,
//           указанных на сайте. Также Alfabet вправе запросить у вас
//           дополнительные документы, подтверждающие ваши возраст и личность.
//         </p>
//       </div>

//       <div className="responsible__component">
//         <div className="responsible__title">
//           Предупреждение игровой зависимости
//         </div>
//         <p>
//           Для большинства игроков азартная игра не является проблемой, однако
//           есть небольшой процент среди игроков, которые подвержены зависимости.
//           Мы подходим к этому вопросу серьезно и предлагаем каждому из наших
//           игроков пройти небольшой тест для самоконтроля и предупреждения
//           игровой зависимости.
//         </p>

//         <p>&mdash; Вы серьезно вовлечены в азартные игры?</p>
//         <p>
//           &mdash; Утратили ли Вы интерес к своей семье, друзьям или хобби из-за
//           азартных игр?
//         </p>
//         <p>&mdash; Вы берете деньги взаймы, чтобы играть?</p>
//         <p>&mdash; Удается ли вам контролировать время, уделяемое для игры?</p>
//         <p>
//           &mdash; Частое посещение букмекерской конторы негативно сказывается на
//           вашей репутации?
//         </p>
//         <p>
//           &mdash; Становитесь ли вы раздражительным и агрессивным во время
//           участия в азартной игре?
//         </p>
//         <p>
//           &mdash; Является ли для вас азартная игра способом уйти от проблем?
//         </p>
//         <p>&mdash; Проиграв, хотите ли вы сразу же отыграться?</p>
//         <p>
//           &mdash; Возникают ли у вас конфликты с близкими из-за азартной игры?
//         </p>
//         <p>&mdash; Считаете ли вы азартную игру основным способом дохода?</p>
//       </div>
//       <div className="responsible__component">
//         <div className="responsible__title">Закрытие игрового счета</div>
//         <p>
//           &mdash; Каждый игрок Alfabet имеет возможность по собственному желанию
//           в любой момент игры закрыть свой счет. Для этого необходимо связаться
//           со службой поддержки букмекера. Вы можете закрыть свой игровой счет,
//           как на время, так и на постоянной основе.{" "}
//         </p>
//         <p>
//           &mdash; Если вы приняли решение об самоисключении из азартной игры, вы
//           должны знать, что в дальнейшем это повлечет за собой следующие
//           факторы:
//         </p>
//         <p>
//           &mdash; Вы не сможете вносить средства и размещать ставки на сайте
//           Alfabet.
//         </p>
//         <p>
//           &mdash; Мы не сможем открыть ваш игровой счёт до истечения выбранного
//           срока или он будет закрыть навсегда.
//         </p>
//         <p>
//           &mdash; При наличии средств на вашем игровом счете вы сможете вывести
//           их и после самоисключения.
//         </p>
//         <p>
//           &mdash; После расторжения соглашения с букмекерской конторой лицо,
//           расторгнувшее соглашение, вправе обратиться в букмекерскую контору с
//           заявлением о заключении нового соглашения. При этом букмекерская
//           контора вправе отказать указанному лицу в заключении нового соглашения
//           без объяснения причин.
//         </p>
//       </div>
//     </div>
//   </div>
// );

const Responsible = () => <span>TBD</span>;
export default Responsible;
