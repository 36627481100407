import {saga1} from "./saga1"
import { all,takeEvery } from 'redux-saga/effects'

//
//
// export function *fooSagas() {
//     yield all([
//         takeEvery("FOO_A", fooASaga),
//         takeEvery("FOO_B", fooBSaga),
//     ]);
// }

export function* rootSaga() {
    yield all([
        takeEvery('SAGA_TEST',saga1),

    ])
}