import { put,take,call,select } from 'redux-saga/effects'


function callFunc(name) {
    setTimeout(()=>{
        console.log('прошла 1 секунда ',name)
    },1000)

}

export function* saga1(action) {
    console.log(action,'action')
    try {

        yield take('SAGA_TEST');
        // console.log('i.m here',user)
        // yield call()
        yield call (callFunc,'1')

    }catch {
        yield put({
            type:'SAGA_ERROR',
            value:'null result'
        })
    }

}