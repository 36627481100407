import React from "react";

// import "./style/data.css";

const Data = () => <span>TBD</span>;

// const Data = () => (
//   <div className="data">
//     <div className="data__header">
//       <span>Безопасные данные</span>
//     </div>

//     <div className="data__container">
//       <div className="data__text">
//         <p>
//           Предметом настоящего Пользовательского соглашения («ПС») являются
//           отношения между ООО «Панорама» (141310, Московская область,
//           Сергиево-Посадский район, г. Сергиев Посад, пр-т Красной Армии, д.
//           158, этаж мансарда, помещение 4, ОГРН 5107746077270), далее
//           «Администрация сайта», являющимся правообладателем Интернет-сервиса,
//           расположенного в сети Интернет по адресу alfabet.ru (далее
//           «Интернет-сервис», равнозначно «Сервис»), и физическим лицом
//           пользователем сети Интернет, далее «Пользователь», по поводу
//           использования Интернет-сервиса и размещенных в нем иных сервисов
//           (далее «Сервисы»).
//         </p>
//         <p>
//           Пользователь обязуется прочитать внимательно настоящее Соглашение в
//           полном объеме, прежде, чем начинать использовать Сайт или любую
//           информацию, размещенную на Сайте, а также предлагаемые сервисы. Сайт
//           предлагает Пользователю использование всех своих функций и сервисов
//           только на условиях полного признания им настоящего Соглашения.
//           Осуществляя доступ к Сайту и перемещение по страницам Сайта,
//           Пользователь принимает без каких-либо ограничений или оговорок
//           установленные Соглашением условия, а также принимает, что любые иные
//           соглашения между Пользователем и Сайтом, которые могут существовать,
//           не должны противоречить условиям, содержащимся в настоящем Соглашении.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">I. ОБЩИЕ УСЛОВИЯ</div>
//         <p>
//           <b>1.1.</b> Использование материалов и сервисов Сайта регулируется
//           нормами действующего законодательства Российской Федерации
//         </p>
//         <p>
//           <b>1.2</b> Настоящее Соглашение является публичной офертой. Каждый
//           раз, получая доступ к материалам Сайта, Пользователь считается
//           принявшим условия настоящего Соглашения.
//         </p>
//         <p>
//           <b>1.3.</b> Администрация Сайта вправе в любое время в одностороннем
//           порядке и без специального предварительного уведомления изменять
//           условия настоящего Соглашения. Такие изменения вступают в силу с
//           момента размещения новой версии Соглашения на Сайте, если иное не
//           предусмотрено новой редакцией Соглашения. При несогласии Пользователя
//           с внесенными изменениями он обязан отказаться от доступа к Сайту и
//           незамедлительно прекратить использование материалов и сервисов Сайта.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">
//           II.РЕГИСТРАЦИЯ ПОЛЬЗОВАТЕЛЯ, ПАРОЛЬ И БЕЗОПАСНОСТЬ
//         </div>
//         <p>
//           <b>2.1.</b> Для получения полного доступа ко всем сервисам Сайта,
//           Пользователю необходимо пройти процедуру регистрации, заполнив все
//           необходимые поля в разделе «Регистрация» и/или процедуру идентификации
//           в пункте приёма ставок или пункте идентификации Администрации сайта.
//         </p>
//         <p>
//           <b>2.2.</b> Пользователи младше 18 (восемнадцати) лет не могут
//           пользоваться сервисами Сайта.
//         </p>

//         <p>
//           <b>2.3.</b> При регистрации Пользователь обязуется предоставить
//           Администрации Сайта полную, точную и соответствующую действительности
//           информацию.
//         </p>

//         <p>
//           <b>2.4.</b> Регистрацией на Сайте Пользователь подтверждает, что
//           ознакомился с правилами пользования Сайтом, понимает их, согласен со
//           всеми правилами и готов их выполнять.
//         </p>

//         <p>
//           <b>2.5.</b> Если Пользователь не согласен с каким-либо условиями
//           настоящего Соглашения, он должен немедленно прекратить пользование
//           сервисами Сайта и покинуть его.
//         </p>

//         <p>
//           <b>2.6.</b> Во время регистрации Пользователь указывает Логин и
//           Пароль. Для авторизации в личном кабинете Пользователя можно
//           использовать адрес электронной почты Пользователя или Логин, указанный
//           им при регистрации на сайте Организатора. Пользователь самостоятельно
//           несет ответственность за конфиденциальность своего логина и пароля.
//           Пользователь несет ответственность за действия, которые осуществляются
//           под его логином и паролем на Сайте, даже в случае, если он предоставил
//           свои данные другому лицу.
//         </p>

//         <p>
//           <b>2.7.</b> Размещая учётные и иные данные и сведения и присоединяясь
//           к Пользовательскому соглашению, Пользователь выражает свое согласие
//           Администрации сайта на обработку учетных и иных данных, на отражение
//           их в профиле Пользователя и соответствующих Сервисах с учетом
//           доступного функционала (который может быть изменен по усмотрению
//           Администрации сайта), а так же на то, что отражаемые в профиле учетные
//           данные и иные данные будут считаться доступными для пользователей сети
//           Интернет с учетом такого функционала. Пользователь соглашается на
//           передачу третьим лицам учетных и иных данных, в том числе для целей их
//           обработки, для обеспечения функционирования Сайта, Сервисов,
//           реализации партнерских и иных программ Сайта при условии обеспечения
//           Администрацией сайта в отношении передаваемых данных со стороны
//           получателя таких данных режима, аналогичного режиму, существующему на
//           Сайте. Цель обработки учетных данных Пользователя заключается в
//           предоставлении последнему возможности использования Сервисов и
//           предоставлении прав использования дополнительных функциональных
//           (программных) возможностей Сайта и Сервисов, проведении рекламных
//           кампаний, предоставлении таргетированной рекламы и Сервисов,
//           проведении статистических исследований и анализа полученных
//           статистических данных, осуществлении иных действий, описанных в
//           Пользовательском соглашении. Обработка учётных данных Пользователя
//           осуществляется в срок с момента регистрации Пользователя и до момента
//           удаления его учётной записи. Пользователь соглашается с тем, что
//           Администрация сайта в процессе обработки учётных данных имеет право
//           осуществлять с учётными данными следующие действия: сбор,
//           систематизацию, накопление, хранение, использование, уничтожение и
//           иные необходимые в целях исполнения Пользовательского соглашения.
//         </p>

//         <p>
//           <b>2.8.</b> Пользователь обязан немедленно уведомить Администрацию
//           Сайта о любом обнаруженном Пользователем случае неавторизованного (не
//           разрешенного Пользователем) доступа с логином и паролем Пользователя
//           и/или о любом нарушении безопасности Сайта.
//         </p>

//         <p>
//           <b>2.9.</b> Администрация Сайта не несет ответственности за
//           последствия потери или порчи данных, которые явились следствием
//           нарушения Пользователем настоящего Соглашения.
//         </p>

//         <p>
//           <b>2.10.</b> Положения настоящего Соглашения являются обязательными
//           для всех ранее зарегистрировавшихся Пользователей.
//         </p>

//         <p>
//           <b>2.11.</b> Администрация Сайта праве устанавливать любые
//           дополнительные требования и ограничения применительно к регистрации на
//           Сайте и использованию Сервисов.{" "}
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">
//           III. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ{" "}
//         </div>
//         <p>
//           <b>3.1.</b> Пользователь имеет право пользоваться всеми бесплатными
//           сервисами Сайта, в том числе участвовать в акциях, проводимых
//           Администрацией на Сайте, при соблюдении необходимых условий,
//           установленных настоящим Соглашением.
//         </p>

//         <p>
//           <b>3.2.</b> Администрация сайта выражает согласие на возможное
//           использование Пользователем Документов, за исключением дизайна страниц
//           сайта или любой информации, содержащейся в сервисах, имеющихся на
//           сайте, исключительно в своих личных целях.
//         </p>

//         <p>
//           <b>3.3.</b> Пользователь обязуется:
//         </p>

//         <p>
//           <b>3.3.1.</b> не создавать более, чем один действующий аккаунт, в том
//           числе не создавать аккаунт с целью введения в заблуждение других
//           Пользователей, Администрации, либо с другими целями, противоречащими
//           обязательствам Пользователя и целям Сайта;
//         </p>

//         <p>
//           <b>3.3.2.</b> не предпринимать действий, которые могут
//           рассматриваться, как нарушающие российское законодательство или нормы
//           международного права, в том числе в сфере интеллектуальной
//           собственности, авторских и/или смежных прав, в частности, Пользователь
//           обязуется:
//           <span>
//             &mdash; размещать и иным способом использовать на Сайте объекты
//             авторского и смежных с ним прав только с согласия правообладателей;
//           </span>
//           <span>
//             &mdash; использовать сервисы и иное содержание Сайта исключительно
//             для личного и некоммерческого использования. Это означает, что
//             Пользователь может просматривать Сайт, использовать сервисы,
//             предоставляемые на Сайте, только для себя и не вправе продавать,
//             обменивать или иным способом передавать этот материал и/или права
//             другим лицам;
//           </span>
//           <span>
//             &mdash; не копировать, не изменять, не распространять, не
//             передавать, не выставлять на своем сайте (блоге и т.п.) или
//             каком-либо ином сайте, не публиковать и не продавать любую
//             информацию, изображения, программное обеспечение, продукты или
//             сервисы, полученные в результате пользования Сайтом.
//           </span>
//         </p>

//         <p>
//           <b>3.3.3.</b> Использовать формы, предназначенные для общения в рамках
//           Сайта, исключительно для общения, в частности, запрещается:
//           <span>
//             &mdash; размещать, передавать, отправлять и иным образом публиковать
//             сообщения, которые могут содержать нецензурные выражения, носить
//             клеветнический или оскорбительный характер, пропагандировать
//             ненависть и дискриминацию людей по расовому, этническому, половому
//             или социальному признакам;
//           </span>
//           <span>
//             &mdash; размещать, передавать, отправлять и иным образом публиковать
//             сообщения, нарушающие права несовершеннолетних лиц и/или причинения
//             им вреда в любой форме, в том числе морального;
//           </span>
//           <span>
//             &mdash; размещать, передавать, отправлять и иным образом публиковать
//             сообщения, нарушающие права различных меньшинств;
//           </span>
//           <span>
//             &mdash; размещать, передавать, отправлять и иным образом публиковать
//             сообщения, выдавая себя за другого человека или представителя
//             организации и/или сообщества, в том числе за Службу поддержки
//             Администрации сайта, работников Администрации сайта, а также
//             вводящие в заблуждение;
//           </span>
//           <span>
//             &mdash; размещать, передавать, отправлять и иным образом публиковать
//             сообщения и материалы, которые нарушают любые права третьих лиц, в
//             том числе право на товарные знаки (знаки обслуживания), коммерческую
//             тайну, и/или для нарушения любых иных прав интеллектуальной
//             собственности третьих лиц;
//           </span>
//           <span>
//             &mdash; размещать, передавать, отправлять и иным образом публиковать
//             сообщения порнографического характера;
//           </span>
//           <span>
//             &mdash; без предварительного согласия Администрации Сайта размещать,
//             передавать, отправлять и иным образом публиковать сообщения
//             рекламного, коммерческого или агитационного характера, содержащие
//             любые виды призывов к покупке, продаже или субсидированию
//             какого-либо продукта, сайта или сервиса;
//           </span>
//           <span>
//             &mdash; без предварительного согласия Администрации Сайта размещать
//             ссылки на другие интернет-ресурсы;
//           </span>
//           <span>
//             &mdash; без предварительного согласия Администрации Сайта вести
//             коммерческую деятельность в пределах Сайта или от его имени.
//           </span>
//         </p>

//         <p>
//           <b>3.3.4.</b> Не предпринимать никаких действий, которые приводят или
//           могут привести к нарушению нормальной работы Сайта и сервисов Сайта, а
//           также других вредоносных действий, в частности, запрещается:
//           <span>
//             &mdash; загрузка, отправка, передача или любой другой способ
//             опубликования каких-либо материалов, содержащих вирусы или другие
//             компьютерные коды, файлы или программы, предназначенные для
//             нарушения, уничтожения либо ограничения функциональности любого
//             компьютерного или телекоммуникационного оборудования или программ,
//             для осуществления несанкционированного доступа, а также серийные
//             номера к коммерческим программным продуктам и программы для их
//             генерации, логины, пароли и прочие средства для получения
//             несанкционированного доступа к платным ресурсам в интернете, а также
//             размещения ссылок на вышеуказанное;
//           </span>
//           <span>
//             &mdash; использование каких-либо автоматических средств сбора
//             информации в то время, когда он находится на Сайте.
//           </span>
//         </p>

//         <p>
//           <b>3.3.5.</b> Руководствоваться принципами добросовестности в процессе
//           участия в азартных играх, в частности, запрещается:
//           <span>
//             &mdash; делиться полученной в процессе азартных информацией с
//             какими-либо другими Пользователями с целью приобретения
//             недобросовестного преимущества;
//           </span>
//           <span>
//             &mdash; использовать недоработки в системе с целью получения
//             недобросовестного преимущества перед другими пользователями или
//             Администрацией;
//           </span>
//           <span>
//             &mdash; мультирегистрация на Сайте запрещена. В случае нарушения
//             этого правила, дополнительный аккаунт пользователя может быть
//             заблокирован;
//           </span>
//           <span>
//             &mdash; совершать любые действия, которые Администрация Сайта или
//             другие Пользователи могут расценить, как недобросовестные.
//           </span>
//         </p>

//         <p>
//           <b>3.3.6.</b> В случае предъявления к Администрации Сайта со стороны
//           третьих лиц каких-либо претензий и/или исков относительно нарушения
//           авторских или смежных с ним прав на размещенный Пользователем на Сайте
//           материал, Пользователь обязуется самостоятельно предпринять все меры
//           по урегулированию данных претензий. При получении любой вышеуказанной
//           претензии Администрация Сайта удаляет материал, к которому имеются
//           претензии третьих лиц, а также вправе удалить аккаунт Пользователя или
//           приостановить доступ Пользователя на Сайт.
//         </p>

//         <p>
//           <b>3.3.7.</b> Выполнять все предписания Администрации Сайта.
//         </p>

//         <p>
//           <b>3.4.</b> Пользователь настоящим соглашается, что любое
//           использование информации, полученной в результате пользования Сайтом,
//           для нарушения условий настоящего Соглашения, может привести к
//           гражданскому иску или уголовному преследованию в соответствии с
//           законодательством Российский Федерации.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">
//           IV. ПРАВА И ОБЯЗАННОСТИ АДМИНИСТРАЦИИ САЙТА
//         </div>
//         <p>
//           <b>4.1.</b> Администрация Сайта имеет право:
//         </p>

//         <p>
//           <b>4.1.1.</b> в любое время по своему усмотрению приостановить, либо
//           отменить регистрацию Пользователя, отказать Пользователю в
//           использовании возможностей Сайта в случае, если у Администрации Сайта
//           возникнут основания предполагать, что Пользователь нарушил одно или
//           несколько из условий настоящего Соглашения или иные Правила,
//           утвержденные Администрацией сайта, регулирующие порядок использования
//           сайта;
//         </p>

//         <p>
//           <b>4.1.2.</b> выдавать Пользователю обязательные для исполнения
//           предписания;
//         </p>

//         <p>
//           <b>4.1.3.</b> без уведомления Пользователя удалить материалы и
//           информацию Пользователя, не соответствующие нормам действующего
//           законодательства Российской Федерации или настоящему Соглашению;
//         </p>

//         <p>
//           <b>4.1.4.</b> в любой момент без каких-либо предварительных
//           специальных уведомлений изменить, удалить или каким-либо иным образом
//           модифицировать любой раздел и/или сервис Сайта включая, но не
//           ограничиваясь:
//           <span>&mdash; ограничение в предоставлении сервисов;</span>
//           <span>&mdash; удаление или изменение контента Сайта;</span>
//           <span>
//             &mdash; ограничение, приостановление или прекращение тех или иных
//             привилегий Пользователя;
//           </span>
//           <span>
//             &mdash; ограничение совместимости Сайта с тем или иным программным
//             обеспечением или компьютерным оборудованием.
//           </span>
//         </p>

//         <p>
//           <b>4.2.</b> Администрация Сайта обязуется:
//         </p>

//         <p>
//           <b>4.2.1.</b> предоставить доступ к Сайту любому Пользователю при
//           условии выполнения Пользователем всех условий и требований настоящего
//           Соглашения.{" "}
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">V. ОСВОБОЖДЕНИЕ ОТ ОТВЕТСТВЕННОСТИ</div>
//         <p>
//           <b>5.1.</b> Администрация не гарантирует, что сервисы Сайта будут
//           предоставляться непрерывно, быстро, надежно и без ошибок.
//         </p>

//         <p>
//           <b>5.2.</b> В случае принятия нормативно-правовых актов органами
//           власти Российской Федерации, затрагивающих целиком или в части
//           функционирования сервисов Сайта, Администрация Сайта сохраняет за
//           собой право любых изменений в функционировании возможностей Сайта,
//           направленных на приведение последнего в соответствие с новыми нормами.
//         </p>

//         <p>
//           <b>5.3.</b> При обнаружении каких-либо публикаций, которые, по мнению
//           Администрации Сайта, входят в какое-либо противоречие с требованиями
//           настоящего Соглашения и/или законодательства Российской Федерации,
//           Администрация Сайта имеет право удалить любую из публикаций.
//         </p>

//         <p>
//           <b>5.4.</b> Пользователь принимает положение о том, что все материалы
//           и сервисы Сайта или любая их часть могут сопровождаться рекламой.
//           Пользователь согласен с тем, что Администрация Сайта не несет
//           какой-либо ответственности и не имеет каких-либо обязательств в связи
//           с такой рекламой.
//         </p>

//         <p>
//           <b>5.5.</b> Пользователь предупрежден о том, что Администрация Сайта
//           не несет ответственности за посещение и использование им внешних
//           ресурсов, ссылки на которые могут содержаться на Сайте.{" "}
//         </p>

//         <p>
//           <b>5.6.</b> Администрация Сайта не несет ответственности за нарушение
//           Пользователем действующего законодательства Российской Федерации, в
//           том числе за нарушение авторских и смежных прав третьих лиц.
//         </p>

//         <p>
//           <b>5.7.</b> Пользователь соглашается с тем, что Администрация сайта
//           может осуществлять сбор обезличенных статистических данных о
//           Пользователях Сайта.
//         </p>

//         <p>
//           <b>5.8.</b> Регистрируя свой адрес электронной почты, Пользователь
//           соглашается получать от Администрации сайта, а также от третьих лиц по
//           заказу Администрации сайта электронные сообщения информационного
//           характера на адрес электронной почты, указанный при регистрации.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">
//           VI. МАТЕРИАЛЫ, ПРЕДОСТАВЛЯЕМЫЕ ПОЛЬЗОВАТЕЛЕМ САЙТУ
//         </div>
//         <p>
//           <b>6.1.</b> Направляя информации в службу поддержки, Пользователь
//           разрешает Администрации Сайта использовать такую информацию для
//           достижения целей, ради которых Пользователем такая информация
//           направлялась.
//         </p>

//         <p>
//           <b>6.2.</b> Администрация Сайта не обязана опубликовывать или иначе
//           использовать любую информацию, предоставленную Пользователем.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">VII. Информационная безопасность</div>
//         <p>
//           <b>7.1.</b> Пользователь не имеет права осуществлять доступ к любой
//           другой информации Сервисов Сайта, помимо собственной информации
//           Пользователя на Сайте, а также к информации, которая является
//           общедоступной в Сервисах Сайта.
//         </p>

//         <p>
//           <b>7.2.</b> При обнаружении лица, осуществляющего действия,
//           квалифицирующиеся, как спам на Почте Администрации сайта,
//           Администрация сайта имеет право блокировать получение почтовых
//           сообщений с почтового адреса указанного лица (в том числе
//           Пользователей) на почтовые адреса Администрации сайта.
//         </p>

//         <p>
//           <b>7.3.</b> Администрация сайта не несет ответственности за
//           сохранность имени учётной записи и пароля Пользователя, если такой
//           Пользователь использует для доступа к Сайту формы, расположенные на
//           внешних Интернет-сайтах.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">
//           VIII. Перерывы в работе Интернет-сервиса
//         </div>
//         <p>
//           <b>8.1.</b> Администрация сайта имеет право производить
//           профилактические работы в Сервисах Сайта с временным приостановлением
//           работы Сервисов Сайта.
//         </p>

//         <p>
//           <b>8.2.</b> В случае наступления форс-мажорных обстоятельств, а также
//           аварий или сбоев в программно-аппаратных комплексах третьих лиц,
//           сотрудничающих с Администрацией сайта, или действий третьих лиц,
//           направленных на приостановку или прекращение функционирования всех или
//           части Сервисов Сайта, возможна приостановка работы Сервисов Сайта без
//           предварительного уведомления Пользователей.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">
//           IX. Обратная связь и порядок рассмотрения претензий
//         </div>
//         <p>
//           <b>9.1.</b> Пользователь, считающий, что его права и интересы нарушены
//           из-за действий Администрации сайта, может направить соответствующее
//           обращение. Рассмотрением обращений занимается Служба поддержки
//           Пользователей сайта в соответствии с общим порядком рассмотрения
//           поступающих запросов.
//         </p>

//         <p>
//           <b>9.2.</b> Все обращения, в том числе относительно работы Сервисов
//           Сайта, Пользователь может направить в Службу поддержки пользователей
//           на E-mail: support@alfabet.ru.
//         </p>
//       </div>

//       <div className="data__text">
//         <div className="data__title">X. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</div>
//         <p>
//           <b>10.1.</b> Пользователь и Администрация сайта соглашаются с тем, что
//           все возможные споры, вытекающие из настоящего Соглашения или связанные
//           с ним, подлежат разрешению в соответствии с действующим
//           законодательством Российской Федерации.{" "}
//         </p>

//         <p>
//           <b>10.2.</b> Нарушение какого-либо из условий настоящего Соглашения
//           считается грубым нарушением Соглашения и повлечет возможность
//           преследования Пользователя Администрацией Сайта в соответствии с
//           действующим законодательством Российской Федерации.
//         </p>

//         <p>
//           <b>10.3.</b> Настоящим Соглашением Администрация Сайта также оставляет
//           за собой все другие доступные меры правовой защиты в соответствии с
//           правом Российской Федерации или любой другой юрисдикции.
//         </p>
//       </div>
//     </div>
//   </div>
// );

export default Data;
