import C from "../../../Constants";

export const bonusBalance = () => {
    return {
        type: C.BONUS_BALANCE_ACCOUNT
    };
};
export const mainBalance = () => {
    return {
        type: C.MAIN_BALANCE_ACCOUNT
    };
};
