import React from "react";
// import "../Main/Components/Cabinet/style/cap.css";

export default function index(props) {
    return (
        <div className="cap">
            <p>{props.text}</p>
        </div>
    );
}
