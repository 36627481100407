import React from "react";

// import "./style/cookies.css";

const Cookies = () => <span>TBD</span>;
// const Cookies = () => (
//     <div className='cookies'>
//         <div className='cookies__header'>
//             <span>Политика Cookies</span>
//         </div>
//         <div className='cookies__text'>
//             <p>
//                 Наш сайт использует файлы cookies и другие похожие технологии,
//                 чтобы гарантировать максимальное удобство всем нашим
//                 пользователям, а именно, предоставить вам необходимую
//                 персонализированную информацию, запоминая и учитывая ваши
//                 предпочтения и интересы в различных областях. Таким образом,
//                 файлы cookie используются для обеспечения работы сайта,
//                 повышения эффективности его работы, а также для получения
//                 аналитической информации. Сразу же обращаем ваше внимание, что
//                 при использовании нашего сайта, вы подтверждаете свое согласие
//                 на использование файлов cookie в соответствии с данным
//                 уведомлением в отношении определенных типа файлов.
//             </p>
//             <p>
//                 Каждый раз при использовании сайта будет сохраняться cookie.
//                 Cookie — это небольшое количество информации, используемое
//                 браузером, которое сохраняется на компьютере участника пари в
//                 течение определенного периода времени. Каждый раз при открытии
//                 участником пари своего счёта будет создаваться и сохраняться
//                 cookie. По выходе cookie будет уничтожаться.
//             </p>

//             <p>
//                 Подробную информацию о cookies можно найти на сайте:{" "}
//                 <a href='http://www.cookiecentral.com/faq/'>
//                     http://www.cookiecentral.com/faq/
//                 </a>
//             </p>
//         </div>
//     </div>
// );

export default Cookies;
