import React, { Component } from "react";
import { connect } from "react-redux";

class BookmakerTable extends Component {
  render() {
    return <div>BookmakerTable</div>;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BookmakerTable);
